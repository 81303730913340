import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Layout from '../layout';
import PostTags from '../components/PostTags/PostTags';
import PostCTA from '../components/PostCTA/PostCTA';
import SEO from '../components/SEO/SEO';
import config from '../../data/SiteConfig';

import styles from './post.module.scss';

import defaultImg from '../../content/img/job-recruiting.svg';

export default class PageTemplate extends React.Component {
  render() {
    const { pageContext, location } = this.props;
    const {
      nexttitle, nextslug, prevtitle, prevslug, slug, cover,
    } = pageContext;
    //

    // data retrieved from query
    const postNode = this.props.data.markdownRemark;
    const post = postNode.frontmatter;
    if (!post.id) {
      post.id = slug;
    }
    if (!post.category_id) {
      post.category_id = config.postDefaultCategoryID;
    }

    if (!post.icon) {
      post.icon = config.postDefaultIcon;
    }

    return (
      <Layout location={location}>
        <div className="post-page md-grid md-grid--no-spacing">
          <Helmet>
            <title>{`${post.title} | ${config.siteTitle}`}</title>
            <link rel="canonical" href={`${config.siteUrl}${post.id}`} />
          </Helmet>
          <SEO postPath={slug} postNode={postNode} postSEO />
          <div className={styles.templatePage}>


            <h1 className={styles.postTitle}>{post.title}</h1>

            <div className={styles.templateContent} dangerouslySetInnerHTML={{ __html: postNode.html }} />
          </div>
        </div>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt
      frontmatter {
        title
        date
      }
      fields {
        slug
        date
      }
    }
  }
`;
